export default function frameCssRendering(frameWidth, frameHeight, renderingStandardLength, renderingToolWidth, renderingToolHeight, selectedFrameType, deviceType) {
  var objectToReturn = {};
  var frameMarginTop;
  // var deviceWidth;
  var mobileMultiplier = 1;
  var marginSubstration;

  if (deviceType === "mobile") {
    frameMarginTop = "0px";
    mobileMultiplier = 0.65;
    marginSubstration = "40px"
  } else if (deviceType === "tablet" ) {
    frameMarginTop = "0px"
    marginSubstration = "35px"
  } else {
    frameMarginTop = `calc((${renderingToolHeight}px - ${frameHeight / renderingStandardLength * renderingToolHeight}px)/2)`;
    marginSubstration = "30px"
  }

  objectToReturn.width = `${frameWidth / renderingStandardLength * renderingToolHeight * mobileMultiplier}px`;
  objectToReturn.height = `${frameHeight / renderingStandardLength * renderingToolHeight * mobileMultiplier}px`;
  // objectToReturn.backgroundImage = `url(${selectedFrameType})`;

  //this is the image where frmae goes. You can enter png or GIF
  objectToReturn.background = "#3B3B3B";
  //mapleFrameRegular
  objectToReturn.backgroundSize = "100% 100%";
  objectToReturn.padding = `${1/renderingStandardLength * renderingToolHeight}px`;
  objectToReturn.marginTop = frameMarginTop;


  // 440px -- 20px padding for frames

  objectToReturn.marginLeft = `calc( (100% - ${marginSubstration} - ${objectToReturn.width}) / 2)`

  // if (frameWidth <= 10 && frameHeight <= 10) {
  //   objectToReturn.marginLeft = -30;
  // }


  // objectToReturn.marginLeft = (frameWidth === frameHeight || (frameWidth === 10 && frameHeight === 8)) ? 0 : `calc( (100% - ${marginSubstration} - ${objectToReturn.width}) / 2)` ;


  return objectToReturn;
}