import React from 'react';
import photo1 from "../../../resources/images/blog/matboard/Capture.PNG";
import photo2 from "../../../resources/images/blog/photoSet1/Blog Post Photos.PNG";
import photo3 from "../../../resources/images/blog/photoset2/23.k.brooke.home.PNG";
import photo4 from "../../../resources/images/blog/jerseys/4.jpeg";
import photo5 from "../../../resources/images/blog/jerseys/5.jpeg";
import photo6 from "../../../resources/images/blog/jerseys/6.jpeg";
import photo7 from "../../../resources/images/blog/jerseys/7.jpeg";
import photo8 from "../../../resources/images/blog/jerseys/8.jpeg";
import photo9 from "../../../resources/images/blog/jerseys/9.jpeg";
import photo10 from "../../../resources/images/blog/jerseys/10.jpeg";
import photo11 from "../../../resources/images/blog/jerseys/11.jpg";
import photo12 from "../../../resources/images/blog/jerseys/12.PNG";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class Matboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>What is a matboard? - Custom-Mat.com</title>
                <meta name="description" content="Matboards are frequently used by framers and professional galleries to bridge the gap between the frame and the artwork. It provides a transitional space between your artwork and picture frame moulding. Matting or matboard also serves to highlight the artwork and surround area from the background wall. "/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                What is a matboard?
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Framing
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Paris K<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                Matboard, also referred to as mat, matting, picture mat, photo mat, passe-partout and paper mat, is a dense, 4-ply surface paper used in the picture framing process. This thick paper material sits between artwork and a frame to give a elevated and polished look.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>
                Matboards are frequently used by framers and professional galleries to bridge the gap between the frame and the artwork. It provides a transitional space between your artwork and picture frame moulding. Matting or matboard also serves to highlight the artwork and surround area from the background wall.

                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"blog-article-photo-description"}>
                    Example of our matboards beautifully fitting the landscape photo into a square frame.
                </div>

                <br/>

                Matboards are used in the process of custom framing. During this process, custom matting is done to create the perfect fit for artwork. A professional matboard workshop provides a bevelled edge at 45 degree angel along the interior of the picture window. This effect from the specialized blade emphasizes the white core ultimately highlighting the central piece.
                <br/><br/>

                Most of the decorative matboards are white core — although there are some speciality matboards that has cream core, black core, solid cores, or rag mats. For your reference, all our matboard at CustomMat are white core matboards.
                <br/><br/>

                <LazyLoad>
                    <img src={photo12} className={"blog-content-photo"}/>
                </LazyLoad>


                <div className={"blog-article-photo-description"}>
                    Examples of coloured matboard is being used to highlight the decor.
                </div>

                <br/>

                Matboards often come in variety of colours to suit your needs. However, a great majority of the matboards that we see are either white or off-white. Most of the frames that picture framers and retailers sell come in white and off-white as well.

                <br/><br/>

                Most of the matboards are acid-free (or acid-neutralized) for your artwork preservation purposes. Almost all matboards out there are created with acid-free materials or acidic components of the paper are removed to maintain the pH level around 7 to meet the artwork preservation requirements. This means acid free matboard is at a higher quality to prevent bleeding and fading over time. Please note that all our mats at CustomMat are acid-free. We only use acid-neutralized matboards.

                <br/><br/>

                Matboards are often created from alpha-cellulose materials. Alpha-cellulose materials are wood fibers that have been processed to remove all the acid and lignin (organic) components to make the acid-free and lignin-free These matboards meet conservation requirements and provide long protection. This insulating gap between your artwork (including watercolor paintings) and the glazing (often acrylic or glass), serves to provide additional protection from the frame surface.

                <br/><br/>

                Often get the uncut mat boards (or pre-cut mats with predefined matboard and opening sizes) from the art supply stores or picture framing stores. Some matboards are often sold with backing boards (or foamboards) and sleeves as an artist presentation package for craft projects and art print retail purposes.

                <br/><br/>

                CustomMat offers these options as well at the same price point but with the gallery quality, acid free, 4 ply matboard in all the color options listed. explore the standard size options for higher quality precision cut matboards in bulk at standard frame and opening sizes.

                <br/><br/>

                Prominent suppliers for matboards include Crescent, Bainbridge, and Peterboro. CustomMat mostly uses Canadian made Peterboro matboards as well as some US made Crescent and UK made Larson-Juhl mats.

                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"blog-article-photo-description"}>
                    Our customer example of using custom cut matboards to fit non-standard sized artworks into retail frames.
                </div>

                <br/>


                CustomMat are matboard experts. We are here to provide our customers with an affordable alternative to custom framing. By combining custom matboards with your picture frames from retail stores (including Amazon, IKEA, Michael's, Costco, Target, or Walmart), you can create customized picture frames at a fraction of the traditional custom framing costs without losing variety or quality.


                <br/><br/>

                With our custom cut matboards, you can fit your artwork in any standards sized frames and non-standard. We do not have a minimum order quantity, so you can order single mats. Free shipping across Canada & the USA. Our matboards come in 20+ variety of colors to suit your needs.

                <br/><br/>

                <LazyLoad>
                    <img src={photo11} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"blog-article-photo-description"}>
                    Using blue double matting to highlight the license plate's letter on IKEA Ribba frame.
                </div>

                <br/>

                We do have single matting and double matting options. We also do sell backing mats, mounting boards, form boards, and sleeves for the presentation purposes. They go well with not just the photos and prints, they also go well with watercolour paintings.

                <br/><br/>

                <b>What is double matting?</b>

                <br/><br/>

                Double matting is an extra layer of matting that sits under your first mat board. It is situated between your primary mat and your artwork. Double matting creates another layer of depth and an additional border to your framed work. It helps to create more focus on what is framed.

                <br/><br/>

                Double matting can be in the same colour as your primary mat, or it can be in a different colour to make your artwork stand out. Often, a dominant colour of your artwork is chosen for the double matting to emphasize the accent colour.

                <br/><br/>

                <b>Do I always need double matting?</b>

                <br/><br/>

                Double matting is completely up to you. Double matting creates another layer of depth. It adds another element of focus to your work. Double matting can be in the same colour as your primary mat, or it can be in a different colour to make your artwork pop! mix and match and feel free to ask for some color samples!

                <br/><br/>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"blog-article-photo-description"}>
                    45 degree bevel cutting example
                </div>


                <br/>

                <b>What is bevel cut?</b>

                <br/><br/>

                A bevel cut is an angled cut. The default cut that we use at CustomMat is beveled at 45 degrees. You can also request the cuts to be made at a 90 degree angle. This will make it so the machine will cut straight down when cutting the window, instead of making the cuts on an angle. Having a beveled cut allows the white interior of the matboard to show through, which adds depth to the work.

                <br/><br/>

                We also offer a variety of etching options if desired. Feel free to request a special message or quote, how about an important year... Does your business need any custom decals on the mat board? ask us what we can do for you in order to completely customize your photo mat. Our graphic design team is happy to whip up something anytime for an additional cost of $2-$10

















                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                    <img src={photo10} className={"blog-content-photo"}/>
                    <img src={photo11} className={"blog-content-photo"}/>
                    <img src={photo12} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>


            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>




        </div>
    }
}