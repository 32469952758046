import React from "react";
import companyLogoIcon from "../../../resources/logo/logo-color.png";
import mobileMenu from "../../../resources/svgIcons/mobile-menu.png";
import closeOutIcon from "../../../resources/svgIcons/close-icon.svg";
import CartSlider from "../comp-cart/cartSlider";
import logoV2 from '../../../resources/logo/logo-v2.svg';
import bag from '../../../resources/logo/bag.png';
import mobileMenuButtonV2 from "../../../resources/logo/navbar-top-menu.svg";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import canadaFlag from "../../../resources/svgIcons/can.png";
import chevRight from "../../../resources/svgIcons/chevron-right.svg";
import chevLeft from "../../../resources/svgIcons/chevron-left.svg";
import caFlag from "../../../resources/images/navbar/caFlag.png";
import usFlag from "../../../resources/images/navbar/usFlag.png";
import Countdown from "react-countdown";


export default class NavBarTop extends React.Component {

  constructor() {
    super();
    this.state = {
      openMobileNavBar: false,
      currentUrlParam: (window.location.pathname + window.location.search),
      onGalleryPage: false,
      french: false,
      openCartModal: false,
      openSupportDropdown: false,
      navbarTopFixed: true,
      fetchStandardMenuButtons: true,
      fetchOurMatboardsButton: false,
      fetchInspirationButton: false,
      fetchSupportButton: false,
      openMatboardsSection: false,
    };
    this.navbarTopSetState = this.navbarTopSetState.bind(this);
    this.totalItemCounter = this.totalItemCounter.bind(this);
  };

  componentDidMount() {
    this.executeQueryParams();
    // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })
  };

  goToPage(url) {
    window.location.href = `${url}`
  }

  navbarTopSetState(dictKey, dictValue) {
    this.setState({[dictKey]: dictValue});
  }

  showCompanyLogo(url) {
    return <a href={this.state.french ? "/fr" : "/"}>
      <div className={"navbar-top-company-name-logo"}>
        <img className={"navbar-top-company-name-logo-image"}
             alt={"Small maple leaf to decorate CustomMat.ca"}
             src={companyLogoIcon}/>
      </div>
    </a>
  };

  fetchEventHeadline() {
    if (this.props.onGoingPromotion) {
      return <h2 className={this.state.french ? "event-message-version2-fr" : "event-message-version2"}>
        {/*Black Friday Code Below*/}
        {this.state.french ? "Vente de fin d'année // 20 % de réduction supplémentaire à la caisse" : `Happy Holidays // Year-End Sale // Extra 20% Off @ Checkout`}<br/>
        {/*{this.props.location === "us" ? "" : "Our deliveries are NOT affected by the Canada Post disruption"}*/}
      </h2>
    } else {
      return <h2 className={this.state.french ? "event-message-version2-fr" : "event-message-version2"}>
        {this.state.french ? "Livraison gratuite partout au Canada" : `Free Shipping & Returns across ${this.props.location === "us" ? "the USA" : "Canada"}`}<br/>

        {/*{this.state.french ? "Livraison gratuite partout au Canada" : `Free Shipping & Returns across ${this.props.location === "us" ? "the USA" : "Canada"}`}<br/>*/}
        {/*{this.props.location === "us" ? "" : "Our deliveries are NOT affected by the Canada Post disruption"}*/}
      </h2>
    }

  }

  totalItemCounter(shoppingCartItems) {
    var totalItemCount = 0;

    if (shoppingCartItems) {
      shoppingCartItems.map((item, key) => {
        totalItemCount  = parseFloat(totalItemCount) + parseFloat(item.orderQuantity);
      })
    }

    if (isNaN(totalItemCount)) {
      totalItemCount = ""
    }

    return totalItemCount;

  }

  executeQueryParams() {
    var urlQueryParams = (window.location.pathname + window.location.search).substr(1);
    if (urlQueryParams === "?whatWeDo") {
      document.getElementById('what-we-do-container').scrollIntoView({behavior: 'smooth'});
    } else if (urlQueryParams === "?howItWorks") {
      document.getElementById('how-it-works-container').scrollIntoView({behavior: 'smooth'});
    }
  };

  fetchCurrency() {
    if (this.props.location === "us") {
      return <div className={"mobile-language-selection-bar"}>
        <img src={usFlag} className={"mobile-language-selection-flag"}/>
        <div className={"mobile-language-selection-flag-text"}>USD$ (EN)</div>
      </div>
    }
    else if (this.props.currency === "US$") {
      return <div className={"mobile-language-selection-bar"}>
        <img src={usFlag} className={"mobile-language-selection-flag"}/>
        <div className={"mobile-language-selection-flag-text"}>USD$ (EN)</div>
      </div>
    }
    else if (this.props.location === "ca" && this.props.french) {
      return <div className={"mobile-language-selection-bar"}>
        <img src={caFlag} className={"mobile-language-selection-flag"}/>
        <div className={"mobile-language-selection-flag-text"}>CAD$ (CA)</div>
      </div>
    }
    else {
     return <div className={"mobile-language-selection-bar"}>
        <img src={caFlag} className={"mobile-language-selection-flag"}/>
        <div className={"mobile-language-selection-flag-text"}>CAD$ (CA)</div>
      </div>
    }
  }

  fetchMobileHeaderIndicator() {
    if (this.state.openMatboardsSection) {
      return <div className={"navbar-mobile-header-indicator-text"}>
        Our Matboards
      </div>
    }
    else if (this.state.fetchInspirationButton) {
      return <div className={"navbar-mobile-header-indicator-text"}>
        Inspiration
      </div>
    }
    else if (this.state.fetchSupportButton) {
      return <div className={"navbar-mobile-header-indicator-text"}>
        Support
      </div>
    }
  }

  clickCloseModalButton() {
    this.setState({openMobileNavBar: false, fetchStandardMenuButtons: true, openMatboardsSection: false, fetchInspirationButton: false, fetchSupportButton: false})
  }

  openMatboardsSection() {
    return <div className={this.state.openMatboardsSection ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Passe-Partout" : "Matboards"}
        </div>
        <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Passe-Partout Personnalisé" : "Fully Custom Matboards"}
          </div>
        </a>

        <a href={this.state.french ? "/precut-mats-fr" : "/precut-mats"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Passe-Partout de Taille Standard" : "Standard Sized, Pre-cut Matboards"}
          </div>
        </a>

        <a href={this.state.french ? "/backing-mat-boards-fr" : "/backing-mat-boards"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Support / Montage Passe-partout" : "Backing & Mounting Matboards"}
          </div>
        </a>

        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Trier par Taille" : "Sort By Size"}
        </div>
        <a href={this.state.french ? "/shop-by-frame-size-fr" : "/shop-by-frame-size"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Acheter par taille de cadre" : "Matboards based on Frame Size"}
          </div>
        </a>

        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Passe-partout Spécialisé" : "Specialty Mats"}
        </div>
        <a href={this.state.french ? "/diploma-frame-matboards-fr" : "/diploma-frame-matboards"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Passe-partout pour Diplômes et Certificats" : "Diploma & Certificate Matboard"}
          </div>
        </a>
      </div>


    </div>
  }

  openInspirationSection() {
    return <div className={this.state.fetchInspirationButton ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <a href={this.state.french ? "/shop-by-looks-fr" : "/shop-by-looks"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Looks tendance" : "Trending Looks"}
          </div>
        </a>

        <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Photos des clients" : "Customer Photos"}
          </div>
        </a>

        <a href={this.state.french ? "/design-blog-main-fr" : "/design-blog-main"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Blog de design" : "Design Blog"}
          </div>
        </a>

        <a href={this.state.french ? "https://www.pinterest.ca/custommatcanada" : "https://www.pinterest.ca/custommatcanada"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Notre page Pinterest" : "Our Pinterest Page"}
          </div>
        </a>
      </div>


    </div>
  }

  openSupportSection() {
    return <div className={this.state.fetchSupportButton ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Prix" : "Pricing"}
        </div>
        <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Prix et Rabais" : "Pricing & Discounts"}
          </div>
        </a>

        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Assistance" : "Support"}
        </div>
        <a href={this.state.french ? "/returns-fr" : "/returns"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Retours" : "Returns"}
          </div>
        </a>

        <a href={this.state.french ? "/shipping-fr" : "/shipping"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Expédition" : "Shipping"}
          </div>
        </a>

        <a href={this.state.french ? "/policies-fr" : "/policies"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Politiques" : "Policies"}
          </div>
        </a>

        <a href={this.state.french ? "/faq-fr" : "/faq"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "FAQ" : "FAQ"}
          </div>
        </a>
      </div>

      <div className={"navbar-top-button-header"}>
        {this.state.french ? "Guides" : "Guides"}
      </div>

      <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Guide de Couleur" : `${this.props.location === "us" ? "Color Guide" : "Colour Guide"}`}
        </div>
      </a>
      <a href={this.state.french ? "/design-guide-fr" : "/design-guide"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Guide de Conception" : "Design Guide"}
        </div>
      </a>

      <div className={"navbar-top-button-header"}>
        {this.state.french ? "Prix" : "Reviews"}
      </div>
      <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Lisez nos avis" : "Read Our Reviews"}
        </div>
      </a>
      <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Publier un avis" : "Post a Review"}
        </div>
      </a>


    </div>
  }

  fetchMobileNavBar() {
    return <div className={"mobile-navbar-options-container"}>

      <div className={"mobile-nav-head-section"}>
        <div className={this.state.fetchStandardMenuButtons ? "no-display" : "navbar-mobile-header-indicator"}>
          <img className={"navbar-mobile-header-indicator-img"} src={chevLeft} onClick={() => this.setState({fetchStandardMenuButtons: true, openMatboardsSection: false, fetchInspirationButton: false, fetchSupportButton: false})} />
          {this.fetchMobileHeaderIndicator()}
        </div>
        <img className={"mobile-nav-close-icon"} src={closeOutIcon} onClick={() => {this.clickCloseModalButton()}}/>

      </div>

      <div className={this.state.fetchStandardMenuButtons ? "display" : "no-display"}>
        <div className={"navbar-top-button-mobile-menu"} onClick={() => this.setState({openMatboardsSection: true, fetchStandardMenuButtons: false})}>
          {/*<a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>*/}
          {/*  <div className={"navbar-top-button-mobile"}>*/}
          {/*    {this.state.french ? "Passe-Partout Personnalisé" : "Our Matboards"}*/}
          {/*  </div>*/}
          {/*  <img className={"navbar-top-button-chevron"} src={chevRight} />*/}
          {/*</a>*/}

          <div className={"navbar-top-button-mobile"}>
            {this.state.french ? "Passe-Partout Personnalisé" : "Our Matboards"}
          </div>
          <img className={"navbar-top-button-chevron"} src={chevRight} />
        </div>

        <div className={"navbar-top-button-mobile-menu"}>
          <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}>
            <div className={"navbar-top-button-mobile"}>
              {this.state.french ? "Comment cela fonctionne" : "How it works"}
            </div>
          </a>
        </div>

        <div className={"navbar-top-button-mobile-menu"} onClick={() => this.setState({fetchInspirationButton: true, fetchStandardMenuButtons: false})}>
          <div className={"navbar-top-button-mobile"}>
            {this.state.french ? "Inspiration" : "Inspiration"}
          </div>
          <img className={"navbar-top-button-chevron"} src={chevRight} />
        </div>

        <div className={"navbar-top-button-mobile-menu"} onClick={() => this.setState({fetchSupportButton: true, fetchStandardMenuButtons: false})}>
          <div className={"navbar-top-button-mobile"}>
            {this.state.french ? "Assistance" : "Support"}
          </div>
          <img className={"navbar-top-button-chevron"} src={chevRight} />
        </div>
      </div>


      {this.openMatboardsSection()}

      {this.openInspirationSection()}

      {this.openSupportSection()}

      {this.fetchCurrency()}

      <div className={"select-currency-options"}>
        <div className={"select-currency-row"} onClick={() => {this.props.changeCurrency("US$"); if (this.state.french === true) {window.location.href="/"}}}>
          <div className={this.props.currency === "US$" ? "select-currency-option select-currency-option-selected" : "select-currency-option"}>

          </div>
          <img  className={"select-currency-flag"} src={usFlag} />
          <div className={"currency-description"}>
            USD$ - English
          </div>
        </div>

        <div className={"select-currency-row"} onClick={() => {this.props.changeCurrency("CA$"); if (this.state.french === true) {window.location.href="/"}}}>
          <div className={this.props.currency === "CA$" && this.state.french === false ?  "select-currency-option select-currency-option-selected" : "select-currency-option" }>

          </div>
          <img  className={"select-currency-flag"} src={caFlag} />
          <div className={"currency-description"}>
            CAD$ - English
          </div>
        </div>

        <div className={"select-currency-row"} onClick={() => {this.props.changeCurrency("CA$"); if (this.state.french === false) {window.location.href="/-fr"}}}>
          <div className={this.props.currency === "CA$" && this.state.french === true ?  "select-currency-option select-currency-option-selected" : "select-currency-option" }>

          </div>
          <img  className={"select-currency-flag"} src={caFlag} />
          <div className={"currency-description"}>
            CAD$ - Francais
          </div>
        </div>
      </div>

      {/*<div className={"navbar-top-button-mobile-menu"}>*/}
      {/*  <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>*/}
      {/*    <div className={"navbar-top-button-mobile"}>*/}
      {/*      {this.state.french ? "Passe-Partout Personnalisé" : "Support"}*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div className={"navbar-top-button-mobile-menu"}>*/}
      {/*  <a href={this.state.french ? "/precut-mats-fr" : "/precut-mats"}>*/}
      {/*    <div className={"navbar-top-button-mobile"}>*/}
      {/*      {this.state.french ? "Passe-Partout de Taille Standard" : "Standard Mats"}*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div className={"navbar-top-button-mobile-menu"}>*/}
      {/*  <a href={this.state.french ? "/backing-mat-boards-fr" : "/backing-mat-boards"}>*/}
      {/*    <div className={"navbar-top-button-mobile"}>*/}
      {/*      {this.state.french ? "Support / Montage Passe-partout" : "Mounting Mats"}*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div className={"navbar-top-button-mobile-menu"}>*/}
      {/*  <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}>*/}
      {/*    <div className={"navbar-top-button-mobile"}>*/}
      {/*      {this.state.french ? "Comment cela fonctionne" : "How it works"}*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div className={"navbar-top-button-mobile-menu"}>*/}
      {/*  <a href={this.state.french ? "/inspiration" : "/inspiration"}>*/}
      {/*    <div className={"navbar-top-button-mobile"}>*/}
      {/*      {this.state.french ? "Clients" : "Customer Photos"}*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</div>*/}

      {/*<div className={"navbar-top-button-mobile-menu"}>*/}
      {/*  <a href={this.state.french ? "/design-blog-main" : "/design-blog-main"} style={{marginLeft: "0"}}>*/}
      {/*    <div className={"navbar-top-button-mobile"}>*/}
      {/*      {this.state.french ? "Blog" : "Design Blog"}*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</div>*/}


      {/*<div className={"navbar-top-button-mobile support-v2"}*/}
      {/*     onMouseOver={() => this.setState({fetchSupport: true})}*/}
      {/*     onMouseLeave={() => this.setState({fetchSupport: false})}*/}
      {/*     onClick={() => this.setState({fetchSupport: !this.state.fetchSupport})}*/}
      {/*     style={{marginLeft: "0"}}*/}
      {/*    // onMouseLeave={() => this.setState({fetchSupport: false})}*/}
      {/*>*/}
      {/*  {this.state.french ? "Assistance" : "Support"}*/}
      {/*  <img className={this.state.fetchSupport ? "support-arrow-arrow-v2-inverse" : "support-arrow-arrow-v2"} src={landingPageArrowLeft}*/}
      {/*       alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}*/}
      {/*  />*/}
      {/*  {this.fetchSupportDropdown()}*/}
      {/*</div>*/}

    </div>
  }

  showMobileNavPanel() {
    this.setState({openMobileNavBar: !this.state.openMobileNavBar});
  };

  fetchSupportDropdown() {
    return <div className={this.state.fetchSupport ? "support-v2-dropdown" : "no-display"}>


      <a href={this.state.french ? "/design-blog-main" : "/design-blog-main"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Blog" : "Design Blog"}
        </div>
      </a>

      <a href={this.state.french ? "/design-guide-fr" : "/design-guide"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Guide de Conception" : "Design Guide"}
        </div>
      </a>

      <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Guide de Couleur" : `${this.props.location === "us" ? "Color Guide" : "Colour Guide"}`}
        </div>
      </a>

      <a href={this.state.french ? "/shipping-fr" : "/shipping"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Livraison" : "Shipping"}
        </div>
      </a>

      <a href={this.state.french ? "/returns-fr" : "/returns"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Retours" : "Returns"}
        </div>
      </a>

      <a href={this.state.french ? "/faq-fr" : "/faq"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "FAQ" : "FAQ"}
        </div>
      </a>

      <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Prix et Remises" : "Pricing & Discounts"}
        </div>
      </a>

      <a href={this.state.french ? "/custom-design-guide-fr" : "/custom-design-guide"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Personnalisée" : "Custom Design"}
        </div>
      </a>


      <a href={this.state.french ? "/review-fr" : "/review"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Publier une évaluation" : "Post a Review"}
        </div>
      </a>
    </div>
  }

  fetchNavBarHovering(hoveredButton) {

    if (hoveredButton === "ourmatboard") {
      return <div className={this.state.fetchOurMatboardsButton ? "navbar-web-hover" : "no-display"}>
        <div className={"navbar-web-hover-column"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Par type de produit" : "By Product Type"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={ this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>{this.state.french ? "Passe-partout Personnalisé" : "Custom Matboard"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/precut-mats-fr" :"/precut-mats"}>{this.state.french ? "Passe-partout de taille standard" : "Standard Size & Pre-cut Matboards"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/backing-mat-boards-fr" : "/backing-mat-boards"}>{this.state.french ? "Montage Passe-partout" : "Backing & Mounting Matboards"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Par taille" : "By Size"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/shop-by-frame-size-fr" : "/shop-by-frame-size"}>{this.state.french ? "basé sur la taille du cadre" : "Matboards Based on Frame Size"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Produits spécialisés" : "Specialty Matboards"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/diploma-frame-matboards-fr" : "/diploma-frame-matboards"}>{this.state.french ? "Passe-Partout de Diplôme" : "Graduation & Certificate Mats"}</a>
          </div>
        </div>
      </div>
    }
    else if (hoveredButton === "inspiration") {
      return <div className={this.state.fetchInspirationButton ? "navbar-web-hover" : "no-display"}>
        <div className={"navbar-web-hover-column"} id={"inspiration-menu-expand"}>
          <div className={"navbar-web-hover-title"}>
            Inspiration
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>{this.state.french ? "Galerie de Photos Clients" : "Customer Photo Gallery"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/design-blog-main-fr" : "/design-blog-main"}>{this.state.french ? "Blog de Conception" : "Design Blog"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={ this.state.french ? "/shop-by-looks-fr" : "/shop-by-looks"}>{this.state.french ? "Design à la Mode" : "Trending Looks"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={"https://www.pinterest.ca/custommatcanada/"}>{this.state.french ? "Nos Tableaux Pinterest" : "Our Pinterest Boards"}</a>
          </div>
        </div>
      </div>
    }
    else if (hoveredButton === "support") {
      return <div className={this.state.fetchSupport ? "navbar-web-hover" : "no-display"}>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Guide" : "Guides"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/how-it-work-fr" : "/how-it-works"}>{this.state.french ? "Comment cela fonctionne" : "How It Works"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"}>{this.state.french ? `Guide des Couleurs` : `${this.props.location === "us" ? " Color" : " Colour"} Guide`}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/design-guide-fr" : "/design-guide"}>{this.state.french ? "Guide de Mesure" : "Measuring Guide"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Assistance" : "Support"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/returns-fr" : "/returns"}>{this.state.french ? "Retour" : "Returns"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/shipping-fr" : "/shipping"}>{this.state.french ? "Expédition" : "Shipping"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/policies-fr" : "/policies"}>{this.state.french ? "Politique" : "Policies"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/faq-fr" : "/faq"}>{this.state.french ? "FAQ" : "FAQ"}</a>
          </div>
        </div>



        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Avis" : "Reviews"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/review-fr" : "/review"}>{this.state.french ? "Poster un Avis" : "Post a Review"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>{this.state.french ? "Lisez nos Avis" : "Read our Reviews"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Tarifs" : "Pricing"}
          </div>
            <div className={"navbar-web-hover-button"}>
                <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>{this.state.french ? "Prix et Rabais" : "Pricing and Discounts"}</a>
            </div>
          {/*<div className={"navbar-web-hover-button"}>*/}
          {/*  <a href={this.state.french ? "/get-quote-fr" : "/get-quote"}>{this.state.french ? "Obtenir un Devis" : "Get a Quote"}</a>*/}
          {/*</div>*/}
          {/*  <div className={"navbar-web-hover-button"}>*/}
          {/*      <a href={this.state.french ? "/quantity-quote-fr" : "/quantity-quote"}>{this.state.french ?  "Devis quantité" : "Quantity Order Quote"}</a>*/}
          {/*  </div>*/}
          {/*  <div className={"navbar-web-hover-button"}>*/}
          {/*      <a href={this.state.french ? "/price-estimate-fr" : "/price-estimate"}>{this.state.french ? "Estimation de Prix" : "Price Estimate Calculator"}</a>*/}
          {/*  </div>*/}
        </div>

      </div>
    }
  }

  fetchNavbarTop(fixedOrNotFixed) {

    if (fixedOrNotFixed) {
      return <div>
        <div className={`display navbar-v2`}>
          <div className={"navbar-top-mobile-button"} onClick={() => {
            this.showMobileNavPanel()
          }}>
            <img src={mobileMenu}
                 alt={this.state.french ? "Cliquez pour développer le menu mobile." : `Click to expand mobile menu.`}/>
          </div>

          <div className={"navbar-top-button-groups"}>

            <div className={this.props.onGoingPromotion ? "notice-bar-version2-event" : "notice-bar-version2"}>
              {this.fetchEventHeadline()}

              <a href={this.state.french ? "/faq-fr" : "/faq"} className={"notice-bar-contact-us mobile-no-display tablet-no-display"}>
                {this.state.french ? "Contactez-nous" : "Contact Us"}
              </a>

              <div className={"notice-bar-region mobile-no-display tablet-no-display"}>
                <img className={"notice-bar-region-icon"}
                     src={this.props.location === "us" ? usFlag : canadaFlag}
                />
                <div className={"notice-bar-region-text mobile-no-display tablet-no-display"}>
                  {this.props.location === "us" ? `United States (USD)` : `Canada (CA$)`}
                </div>
              </div>
            </div>


            <div className={"logo-vertical-v2"}>
              <a href={this.state.french ? "/-fr" : "/"}>
                <img src={logoV2}
                     alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
                />
              </a>
            </div>

            <div href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}
                 className={"navbar-button-v2 how-it-works-v2"}
                 onMouseEnter={() => this.setState({fetchOurMatboardsButton: true})}
                 onMouseLeave={() => this.setState({fetchOurMatboardsButton: false})}
            >
              {this.state.french ? "Notre Passe-Partout" : "Our Matboards"}
              {this.fetchNavBarHovering("ourmatboard")}

            </div>


            <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}
               className={"navbar-button-v2 how-it-works-v2"}>
              {this.state.french ? "Comment ça Marche" : "How it works"}
            </a>

            <a href={this.state.french ? "/inspiration" : "/inspiration"} className={"navbar-button-v2 inspiration-v2"}
               onMouseEnter={() => this.setState({fetchInspirationButton: true})}
               onMouseLeave={() => this.setState({fetchInspirationButton: false})}
            >
              {this.state.french ? "Inspiration" : "Inspiration"}
              {this.fetchNavBarHovering("inspiration")}
            </a>

            <div className={"navbar-button-v2 support-v2"}
                 onMouseOver={() => this.setState({fetchSupport: true})}
                 onMouseLeave={() => this.setState({fetchSupport: false})}
            >
              {this.state.french ? "Assistance" : "Support"}

              {this.fetchNavBarHovering("support")}
              {/*{this.fetchSupportDropdown()}*/}
            </div>

            <div className={"navbar-button-v2 enfr-v2"}>
              <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname.slice(0, -3) : window.location.pathname}>
                <span className={this.state.french ? "" : "navbar-top-web-language-selected"}>
                  EN
                </span>
              </a>

              <span> | </span>

              <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname : `${window.location.pathname}-fr`}>
                <span className={this.state.french ? "navbar-top-web-language-selected" : ""}>
                  FR
                </span>
              </a>
            </div>

            <div className={"bag-v2 mobile-menu-button"} id={"mobile-menu-button-tablet"}
                 style={{paddingTop: "3px"}}
                 onClick={() => {
                   this.showMobileNavPanel()
                 }}>

              <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"} src={mobileMenuButtonV2}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"} alt={"Click here for menu."}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
                   onClick={() => {
                     this.showMobileNavPanel()
                   }} src={closeOutIcon}/>
            </div>

            <div className={"bag-v2"} onClick={() => {
              this.props.showShoppingCartModal("open")
            }}>
              <img className={"bag-icon-v2"} src={bag}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <div className={"cart-item-count-container"}>
                <div className={"cart-item-count"}>
                  {this.totalItemCounter(this.props.shoppingCartItems)}
                </div>
              </div>
            </div>

            <div className={"navbar-start-button mobile-no-display"}>
              <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                <div className={"landing-page-button-standard-v2"}>
                  {this.state.french ? "Je crée " : "START DESIGNING"}
                </div>
              </a>
            </div>
          </div>

          <div className={this.state.openMobileNavBar ? "mobile-navbar-open-wide" : "no-display"}>
            {this.fetchMobileNavBar()}
            <div style={{width: "100%", height: "100vh", zIndex: 10}} onClick={() => {this.clickCloseModalButton()}}>

            </div>
          </div>

        </div>
      </div>
    } else {
      return <div>
        <div className={`display navbar-v2-non-fixed`}>
          <div className={"navbar-top-mobile-button"} onClick={() => {
            this.showMobileNavPanel()
          }}>
            <img src={mobileMenu}
                 alt={this.state.french ? "Cliquez pour développer le menu mobile." : `Click to expand mobile menu.`}/>
          </div>

          <div className={"navbar-top-button-groups"}>

            <div className={this.props.onGoingPromotion ? "notice-bar-version2-event" : "notice-bar-version2"}>
              {this.fetchEventHeadline()}

              <a href={this.state.french ? "/faq-fr" : "/faq"} className={"notice-bar-contact-us mobile-no-display tablet-no-display"}>
                {this.state.french ? "Contactez-nous" : "Contact Us"}
              </a>

              <div className={"notice-bar-region mobile-no-display tablet-no-display"}>
                <img className={"notice-bar-region-icon"}
                     src={this.props.location === "us" ? usFlag : canadaFlag}
                />
                <div className={"notice-bar-region-text mobile-no-display"}>
                  {this.props.location === "us" ? `United States (USD)` : `Canada (CA$)`}
                </div>
              </div>
            </div>


            <div className={"logo-vertical-v2"}>
              <a href={this.state.french ? "/-fr" : "/"}>
                <img src={logoV2}
                     alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
                />
              </a>
            </div>

            <div href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}
                 className={"navbar-button-v2 how-it-works-v2"}
                 onMouseEnter={() => this.setState({fetchOurMatboardsButton: true})}
                 onMouseLeave={() => this.setState({fetchOurMatboardsButton: false})}
            >
              {this.state.french ? "Notre Passe-Partout" : "Our Matboards"}
              {this.fetchNavBarHovering("ourmatboard")}

            </div>




            <a href={this.state.french ? "/inspiration" : "/inspiration"} className={"navbar-button-v2 inspiration-v2"}
               onMouseEnter={() => this.setState({fetchInspirationButton: true})}
               onMouseLeave={() => this.setState({fetchInspirationButton: false})}
            >
              {this.state.french ? "Inspiration" : "Inspiration"}
              {this.fetchNavBarHovering("inspiration")}
            </a>

            <div className={"navbar-button-v2 support-v2"}
                 onMouseOver={() => this.setState({fetchSupport: true})}
                 onMouseLeave={() => this.setState({fetchSupport: false})}
            >
              {this.state.french ? "Assistance" : "Guide & Support"}

              {this.fetchNavBarHovering("support")}
              {/*{this.fetchSupportDropdown()}*/}
            </div>

            <div className={"navbar-button-v2 enfr-v2"}>
              <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname.slice(0, -3) : window.location.pathname}>
                <span className={this.state.french ? "" : "navbar-top-web-language-selected"}>
                  EN
                </span>
              </a>

              <span> | </span>

              <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname : `${window.location.pathname}-fr`}>
                <span className={this.state.french ? "navbar-top-web-language-selected" : ""}>
                  FR
                </span>
              </a>
            </div>

            <div className={"bag-v2 mobile-menu-button"} id={"mobile-menu-button-tablet"}
                 style={{paddingTop: "3px"}}
                 onClick={() => {
                   this.showMobileNavPanel()
                 }}>

              <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"} src={mobileMenuButtonV2}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"} alt={"Click here for menu."}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
                   onClick={() => {
                     this.showMobileNavPanel()
                   }} src={closeOutIcon}/>
            </div>

            <div className={"bag-v2"} onClick={() => {
              this.props.showShoppingCartModal("open")
            }}>
              <img className={"bag-icon-v2"} src={bag}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <div className={"cart-item-count-container"}>
                <div className={"cart-item-count"}>
                  {this.totalItemCounter(this.props.shoppingCartItems)}
                </div>
              </div>
            </div>

            <div className={"navbar-start-button mobile-no-display"}>
              <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                <div className={"landing-page-button-standard-v2"}>
                  {this.state.french ? "Je crée " : "START DESIGNING"}
                </div>
              </a>
            </div>
          </div>

          <div className={this.state.openMobileNavBar ? "mobile-navbar-open-wide" : "no-display"}>
            {this.fetchMobileNavBar()}
            <div style={{width: "100%", height: "100vh", zIndex: 10}} onClick={() => {this.clickCloseModalButton()}}>

            </div>
          </div>

        </div>
      </div>

    }



    // if (wideTrueOrFalse) {
    //   return <div>
    //     <div className={`display navbar-v2`}>
    //       <div className={"navbar-top-mobile-button"} onClick={() => {
    //         this.showMobileNavPanel()
    //       }}>
    //         <img src={mobileMenu}
    //              alt={this.state.french ? "Cliquez pour développer le menu mobile." : `Click to expand mobile menu.`}/>
    //       </div>
    //
    //       <div className={"navbar-top-button-groups"}>
    //
    //         <div className={this.state.french ? "notice-bar-version2" : "notice-bar-version2"}>
    //           <h2 className={this.state.french ? "event-message-version2-fr" : "event-message-version2"}>
    //             {this.state.french ? "49$+ Livraison Gratuite" : `${this.props.location === "us" ? "$39+" : "$49+"} Free Shipping to ${this.props.location === "us" ? "USA" : "Canada"}`}
    //             {/*<Countdown date={Date.parse('2023-09-05T12:00:00')} daysInHours={true}></Countdown>*/}
    //
    //             {/*{this.state.french ? "20% de réduction supplémentaire // Utilisez le code promo 2023LD20" : " Labour Day Sale // Additional 20% Off // Use Promo Code 2023LD20 at Checkout"}*/}
    //           </h2>
    //
    //           <a href={this.state.french ? "/faq-fr" : "/faq"} className={"notice-bar-contact-us mobile-no-display"}>
    //             {this.state.french ? "Contactez-nous" : "Contact Us"}
    //           </a>
    //
    //           <div className={"notice-bar-region mobile-no-display"}>
    //             <img className={"notice-bar-region-icon"}
    //                  src={this.props.location === "us" ? usFlag : canadaFlag}
    //             />
    //             <div className={"notice-bar-region-text mobile-no-display"}>
    //               {this.props.location === "us" ? `United States (USD)` : `Canada (CA$)`}
    //             </div>
    //           </div>
    //         </div>
    //
    //
    //         <div className={"logo-vertical-v2"}>
    //           <a href={this.state.french ? "/-fr" : "/"}>
    //             <img src={logoV2}
    //                  alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
    //             />
    //           </a>
    //         </div>
    //
    //         <div href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}
    //              className={"navbar-button-v2 how-it-works-v2"}
    //              onMouseEnter={() => this.setState({fetchOurMatboardsButton: true})}
    //              onMouseLeave={() => this.setState({fetchOurMatboardsButton: false})}
    //         >
    //           {this.state.french ? "Notre Passe-Partout" : "Our Matboards"}
    //           {this.fetchNavBarHovering("ourmatboard")}
    //
    //         </div>
    //
    //
    //         <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}
    //            className={"navbar-button-v2 how-it-works-v2"}>
    //           {this.state.french ? "Comment ça Marche" : "How it works"}
    //         </a>
    //
    //         <a href={this.state.french ? "/inspiration" : "/inspiration"} className={"navbar-button-v2 inspiration-v2"}
    //            onMouseEnter={() => this.setState({fetchInspirationButton: true})}
    //            onMouseLeave={() => this.setState({fetchInspirationButton: false})}
    //         >
    //           {this.state.french ? "Inspiration" : "Inspiration"}
    //           {this.fetchNavBarHovering("inspiration")}
    //         </a>
    //
    //         <div className={"navbar-button-v2 support-v2"}
    //              onMouseOver={() => this.setState({fetchSupport: true})}
    //              onMouseLeave={() => this.setState({fetchSupport: false})}
    //         >
    //           {this.state.french ? "Assistance" : "Support"}
    //
    //           {this.fetchNavBarHovering("support")}
    //           {/*{this.fetchSupportDropdown()}*/}
    //         </div>
    //
    //         <div className={"navbar-button-v2 enfr-v2"}>
    //           <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname.slice(0, -3) : window.location.pathname}>
    //             <span className={this.state.french ? "" : "navbar-top-web-language-selected"}>
    //               EN
    //             </span>
    //           </a>
    //
    //           <span> | </span>
    //
    //           <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname : `${window.location.pathname}-fr`}>
    //             <span className={this.state.french ? "navbar-top-web-language-selected" : ""}>
    //               FR
    //             </span>
    //           </a>
    //         </div>
    //
    //         <div className={"bag-v2 mobile-menu-button"} id={"mobile-menu-button-tablet"}
    //              style={{paddingTop: "3px"}}
    //              onClick={() => {
    //                this.showMobileNavPanel()
    //              }}>
    //
    //           <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"} src={mobileMenuButtonV2}
    //                alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
    //           />
    //           <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"} alt={"Click here for menu."}
    //                alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
    //                onClick={() => {
    //                  this.showMobileNavPanel()
    //                }} src={closeOutIcon}/>
    //         </div>
    //
    //         <div className={"bag-v2"} onClick={() => {
    //           this.props.showShoppingCartModal("open")
    //         }}>
    //           <img className={"bag-icon-v2"} src={bag}
    //                alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
    //           />
    //           <div className={"cart-item-count-container"}>
    //             <div className={"cart-item-count"}>
    //               {this.totalItemCounter(this.props.shoppingCartItems)}
    //             </div>
    //           </div>
    //         </div>
    //
    //         <div className={"navbar-start-button mobile-no-display"}>
    //           <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
    //             <div className={"landing-page-button-standard-v2"}>
    //               {this.state.french ? "Je crée " : "START DESIGNING"}
    //             </div>
    //           </a>
    //         </div>
    //       </div>
    //
    //       <div className={this.state.openMobileNavBar ? "mobile-navbar-open-wide" : "no-display"}>
    //         {this.fetchMobileNavBar()}
    //       </div>
    //
    //     </div>
    //   </div>
    // } else {
    //   return <div>
    //     <div className={`display navbar-v2`}>
    //       <div className={"navbar-top-mobile-button"} onClick={() => {
    //         this.showMobileNavPanel()
    //       }}>
    //         <img src={mobileMenu}
    //              alt={this.state.french ? "Cliquez pour développer le menu mobile." : `Click to expand mobile menu.`}/>
    //       </div>
    //
    //       <div className={"navbar-top-button-groups"}>
    //
    //         <div className={this.state.french ? "notice-bar-version2" : "notice-bar-version2"}>
    //           <h2 className={this.state.french ? "event-message-version2-fr" : "event-message-version2"}>
    //             {this.state.french ? "49$+ Livraison Gratuite" : `${this.props.location === "us" ? "$39+" : "$49+"} Free Shipping to ${this.props.location === "us" ? "USA" : "Canada"}`}
    //             {/*<Countdown date={Date.parse('2023-09-05T12:00:00')} daysInHours={true}></Countdown>*/}
    //
    //             {/*{this.state.french ? "20% de réduction supplémentaire // Utilisez le code promo 2023LD20" : " Labour Day Sale // Additional 20% Off // Use Promo Code 2023LD20 at Checkout"}*/}
    //           </h2>
    //
    //           <a href={this.state.french ? "/faq-fr" : "/faq"} className={"notice-bar-contact-us mobile-no-display"}>
    //             {this.state.french ? "Contactez-nous" : "Contact Us"}
    //           </a>
    //
    //           <div className={"notice-bar-region mobile-no-display"}>
    //             <img className={"notice-bar-region-icon"}
    //                  src={this.props.location === "us" ? usFlag : canadaFlag}
    //             />
    //             <div className={"notice-bar-region-text mobile-no-display"}>
    //               {this.props.location === "us" ? `United States (USD)` : `Canada (CA$)`}
    //             </div>
    //           </div>
    //         </div>
    //
    //
    //         <div className={"logo-vertical-v2"}>
    //           <a href={this.state.french ? "/-fr" : "/"}>
    //             <img src={logoV2}
    //                  alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
    //             />
    //           </a>
    //         </div>
    //
    //         <div href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}
    //              className={"navbar-button-v2 how-it-works-v2"}
    //              onMouseEnter={() => this.setState({fetchOurMatboardsButton: true})}
    //              onMouseLeave={() => this.setState({fetchOurMatboardsButton: false})}
    //         >
    //           {this.state.french ? "Notre Passe-Partout" : "Our Matboards"}
    //           {this.fetchNavBarHovering("ourmatboard")}
    //
    //         </div>
    //
    //
    //         <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}
    //            className={"navbar-button-v2 how-it-works-v2"}>
    //           {this.state.french ? "Comment ça Marche" : "How it works"}
    //         </a>
    //
    //         <a href={this.state.french ? "/inspiration" : "/inspiration"} className={"navbar-button-v2 inspiration-v2"}
    //            onMouseEnter={() => this.setState({fetchInspirationButton: true})}
    //            onMouseLeave={() => this.setState({fetchInspirationButton: false})}
    //         >
    //           {this.state.french ? "Inspiration" : "Inspiration"}
    //           {this.fetchNavBarHovering("inspiration")}
    //         </a>
    //
    //         <div className={"navbar-button-v2 support-v2"}
    //              onMouseOver={() => this.setState({fetchSupport: true})}
    //              onMouseLeave={() => this.setState({fetchSupport: false})}
    //         >
    //           {this.state.french ? "Assistance" : "Support"}
    //
    //           {this.fetchNavBarHovering("support")}
    //           {/*{this.fetchSupportDropdown()}*/}
    //         </div>
    //
    //         <div className={"navbar-button-v2 enfr-v2"}>
    //           <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname.slice(0, -3) : window.location.pathname}>
    //             <span className={this.state.french ? "" : "navbar-top-web-language-selected"}>
    //               EN
    //             </span>
    //           </a>
    //
    //           <span> | </span>
    //
    //           <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname : `${window.location.pathname}-fr`}>
    //             <span className={this.state.french ? "navbar-top-web-language-selected" : ""}>
    //               FR
    //             </span>
    //           </a>
    //         </div>
    //
    //         <div className={"bag-v2 mobile-menu-button"} id={"mobile-menu-button-tablet"}
    //              style={{paddingTop: "3px"}}
    //              onClick={() => {
    //                this.showMobileNavPanel()
    //              }}>
    //
    //           <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"} src={mobileMenuButtonV2}
    //                alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
    //           />
    //           <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"} alt={"Click here for menu."}
    //                alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
    //                onClick={() => {
    //                  this.showMobileNavPanel()
    //                }} src={closeOutIcon}/>
    //         </div>
    //
    //         <div className={"bag-v2"} onClick={() => {
    //           this.props.showShoppingCartModal("open")
    //         }}>
    //           <img className={"bag-icon-v2"} src={bag}
    //                alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
    //           />
    //           <div className={"cart-item-count-container"}>
    //             <div className={"cart-item-count"}>
    //               {this.totalItemCounter(this.props.shoppingCartItems)}
    //             </div>
    //           </div>
    //         </div>
    //
    //         <div className={"navbar-start-button mobile-no-display"}>
    //           <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
    //             <div className={"landing-page-button-standard-v2"}>
    //               {this.state.french ? "Je crée " : "START DESIGNING"}
    //             </div>
    //           </a>
    //         </div>
    //       </div>
    //
    //       <div className={this.state.openMobileNavBar ? "mobile-navbar-open-wide" : "no-display"}>
    //         {this.fetchMobileNavBar()}
    //       </div>
    //
    //     </div>
    //   </div>
    // }

    // } else {
    //   return <div>
    //     <div className={`display navbar-v2-narrow`}>
    //       <div className={"navbar-top-mobile-button"} onClick={() => {this.showMobileNavPanel()}}>
    //         <img src={mobileMenu} alt={this.state.french ? "Cliquez pour développer le menu mobile." : "Click to expand the mobile menu."}/>
    //       </div>
    //
    //       <div className={"navbar-top-button-groups-narrow"}>
    //
    //         <div className={"logo-vertical-v2-narrow"}>
    //           <a href={this.state.french ? "/-fr" : "/"}>
    //             <img src={logoV2}
    //              alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
    //             />
    //           </a>
    //         </div>
    //
    //         <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"} className={"navbar-button-v2-narrow how-it-works-v2"}
    //            style={{marginTop: this.state.french ? "14.5px" : ""}}
    //         >
    //           {this.state.french ? "PASSE-PARTOUT PERSONNALISÉ" : "Custom Matboards"}
    //         </a>
    //
    //         <a href={this.state.french ? "/precut-mats-fr" : "/precut-mats"} className={"navbar-button-v2-narrow how-it-works-v2"}
    //            style={{marginTop: this.state.french ? "14.5px" : ""}}
    //         >
    //           {this.state.french ? "Passe-partout de taille standard" : "Standard Mats"}
    //         </a>
    //
    //         <a href={this.state.french ? "//backing-mat-boards-fr" : "/backing-mat-boards"} className={"navbar-button-v2-narrow how-it-works-v2"}
    //            style={{marginTop: this.state.french ? "14.5px" : ""}}
    //         >
    //           {this.state.french ? "Support / Montage Passe-partout" : "Mounting Mats"}
    //         </a>
    //
    //         <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"} className={"navbar-button-v2-narrow how-it-works-v2"}
    //            style={{marginTop: this.state.french ? "14.5px" : ""}}
    //         >
    //           {this.state.french ? "Comment cela fonctionne" : "How it works"}
    //         </a>
    //
    //         <a href={this.state.french ? "/design-blog-main" : "/design-blog-main"} className={"navbar-button-v2-narrow inspiration-v2"}
    //            style={{marginLeft: this.state.french ? "0" : "", marginTop: this.state.french ? "24.5px" : ""}}
    //         >
    //           {this.state.french ? "Blog" : "Design Blog"}
    //         </a>
    //
    //
    //         <div className={"navbar-button-v2-narrow support-v2"}
    //              onMouseOver={() => this.setState({fetchSupport: true})}
    //              onMouseLeave={() => this.setState({fetchSupport: false})}
    //              onClick={() => this.setState({fetchSupport: !this.state.fetchSupport})}
    //              style={{marginLeft: this.state.french ? "30px" : "", marginTop: this.state.french ? "24.5px" : ""}}
    //         >
    //           {this.state.french ? "Assistance" : "Support"}
    //           <img className={this.state.fetchSupport ? "support-arrow-arrow-v2-inverse" : "support-arrow-arrow-v2"} src={landingPageArrowLeft} alt={this.state.french ? "Cliquez pour développer le menu mobile." : "Click to expand the mobile menu."}/>
    //           {this.fetchSupportDropdown()}
    //         </div>
    //
    //         <div className={"navbar-button-v2-narrow enfr-v2"}>
    //           <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname.slice(0,-3) : window.location.pathname}>
    //             <span className={this.state.french ? "" : "navbar-top-web-language-selected"}>
    //               EN
    //             </span>
    //           </a>
    //           |
    //           <a href={window.location.pathname.slice(-2) === "fr" ? window.location.pathname : `${window.location.pathname}-fr`}>
    //             <span className={this.state.french ? "navbar-top-web-language-selected" : ""}>
    //               FR
    //             </span>
    //           </a>
    //         </div>
    //
    //         <div className={"bag-v2-narrow mobile-menu-button"} id={"mobile-menu-button-tablet"}
    //              style={{paddingTop: "3px"}}
    //              onClick={() => {this.showMobileNavPanel()}}>
    //           <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"}
    //                src={mobileMenuButtonV2}
    //                alt={this.state.french ? "Cliquez pour développer le menu mobile." : "Click to expand the mobile menu."}
    //           />
    //           <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"}
    //                alt={"Click here for menu."}
    //                onClick={() => {this.showMobileNavPanel()}} src={closeOutIcon}/>
    //         </div>
    //
    //         <div className={"bag-v2-narrow"} onClick={() => {this.props.showShoppingCartModal("open")}}>
    //           <img className={"bag-icon-v2"} src={bag}
    //             alt={this.state.french ? "Cliquez pour afficher les détails du panier." : "Click to view shopping cart details."}
    //           />
    //           <div className={"cart-item-count-container"}>
    //             <div className={"cart-item-count"}>
    //               {this.totalItemCounter(this.props.shoppingCartItems)}
    //             </div>
    //           </div>
    //         </div>
    //
    //       </div>
    //
    //       <div className={this.state.openMobileNavBar ? "mobile-navbar-open" : "no-display"}>
    //         {this.fetchMobileNavBar()}
    //       </div>
    //
    //     </div>
    //   </div>
    // }

  }

  render(props) {
    return <div className={"navbar-top-container"}>

      {(window.location.pathname === "/" || window.location.pathname === "/-fr" ||
          window.location.pathname === "/how-it-works" || window.location.pathname === "/how-it-works-fr" ||
          window.location.pathname === "/inspiration" || window.location.pathname === "/inspiration-fr" ||
          window.location.pathname === "/design-blog-main" || window.location.pathname === "/design-blog-main-fr" ||
          window.location.pathname === "/shop-by-looks" || window.location.pathname === "/shop-by-looks-fr" ||
          window.location.pathname === "/shipping" || window.location.pathname === "/shipping-fr" ||
          window.location.pathname === "/policies" || window.location.pathname === "/policies-fr" ||
          window.location.pathname === "/faq" || window.location.pathname === "/faq-fr" ||
          window.location.pathname === "/faqs" || window.location.pathname === "/faqs-fr" ||
          window.location.pathname === "/shop-by-looks" || window.location.pathname === "/shop-by-looks-fr" ||
          window.location.pathname === "/review" || window.location.pathname === "/review-fr" ||
          window.location.pathname === "/read-our-reviews" || window.location.pathname === "/read-our-reviews-fr" ||
          window.location.pathname === "/pricing-and-discounts" || window.location.pathname === "/pricing-and-discounts-fr" ||
          window.location.pathname === "/get-quote" || window.location.pathname === "/get-quote-fr" ||
          window.location.pathname === "/quantity-quote" || window.location.pathname === "/quantity-quote-fr" ||
          window.location.pathname === "/price-estimate" || window.location.pathname === "/price-estimate-fr" ||
          window.location.pathname === "/gallery" || window.location.pathname === "/gallery-fr" ||
          window.location.pathname === "/colour-guide" || window.location.pathname === "/colour-guide-fr" ||
          window.location.pathname === "/returns" || window.location.pathname === "/returns-fr" ||
          window.location.pathname === "/shipping-info" || window.location.pathname === "/shipping-info-fr" ||
          window.location.pathname === "/workflow"


      )
          ? this.fetchNavbarTop(false) : this.fetchNavbarTop(true)}

      {/*{this.fetchNavbarTop()}*/}

      <CartSlider
          openShoppingCartModal={this.props.openShoppingCartModal}
          navbarTopSetState={this.navbarTopSetState}
          showShoppingCartModal={this.props.showShoppingCartModal}
          shoppingCartItems={this.props.shoppingCartItems}
          parentSetStateCallback={this.props.parentSetStateCallback}
          updateItemList={this.props.updateItemList}
          calculateItemSubtotal={this.props.calculateItemSubtotal}
          totalItemCounter={this.totalItemCounter}
          getDiscountMultiplier={this.props.getDiscountMultiplier}
          french={this.state.french}
          location={this.props.location}
          currency={this.props.currency}
          fxConversion={this.props.fxConversion}
          onGoingPromotion={this.props.onGoingPromotion}
          promotionRate={this.props.promotionRate}
      />

      <div className={(this.state.fetchInspirationButton || this.state.fetchOurMatboardsButton || this.state.fetchSupport) ? "display-bottom-blur" : "no-display"}>


      </div>

    </div>
  }
}