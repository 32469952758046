import React from 'react';
import photo1 from "../../../resources/images/blog/jerseys/3.jpeg";
import photo2 from "../../../resources/images/blog/jerseys/1.jpeg";
import photo3 from "../../../resources/images/blog/jerseys/7.jpeg";
import photo4 from "../../../resources/images/blog/jerseys/4.jpeg";
import photo5 from "../../../resources/images/blog/jerseys/5.jpeg";
import photo6 from "../../../resources/images/blog/jerseys/6.jpeg";
import photo7 from "../../../resources/images/blog/jerseys/7.jpeg";
import photo8 from "../../../resources/images/blog/jerseys/8.jpeg";
import photo9 from "../../../resources/images/blog/jerseys/9.jpeg";
import photo10 from "../../../resources/images/blog/jerseys/10.jpeg";
import photo11 from "../../../resources/images/blog/jerseys/11.jpg";
import photo12 from "../../../resources/images/blog/jerseys/12.PNG";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class ShadowBoxMemorabilia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Jersey Framing & Shadow Box Sports Memorabilia Ideas</title>
                <meta name="description" content="Enhance memorabilia with frames and custom cut photo mats to create preserved stylish pieces."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Jersey Framing & Shadow Box Sports Memorabilia Ideas
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Memorabilia Framing
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>
                This is a friendly reminder not to forget to add matboards to your shadow boxes. I repeat, do not forget your mat boards! What is a mat board? A matboard is a durable paper-based layer positioned between an art piece and the glass of a frame.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>

                It is a crucially elevating element for professional artists and photographers, gallery owners and museums alike.  When working with a shadow box the mat board is the precession material that creates and controls the depth of the displayed piece. This is the difference between a basic vertical box with a glass lid and a focused positioned statement piece.

                <br/><br/>

                <LazyLoad>
                    <img src={photo11} className={"blog-content-photo"}/>
                </LazyLoad>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <i>Enhance memorabilia with frames and custom cut photo mats to create preserved stylish pieces.</i>

                <br/><br/>

                Did you know that you can self source custom cut mat board for your shadow box displays? Take your dad's ugly license plate or that precious varsity hockey jersey into your own hands.

                <br/><br/>


                <LazyLoad>
                    <img src={photo12} className={"blog-content-photo"}/>
                </LazyLoad>
                <div style={{textAlign: "center"}}>
                    <i>Left: Jersey framing without a custom matboard <br/> Right: Sports memorabilia with a layer of custom matting</i>
                </div>
                <br/><br/>

                I like to source basic shadow boxes from amazon for my memorabilia. I find custom framing shops extremely expensive for these projects. To avoid those, I consider the following:

                <ol>
                    <li>My display piece is meant to be displayed.</li>
                    <li>My displayed item will likely be textured.</li>
                    <li>Basic black frames or white frames are best for these projects.</li>
                    <li>All essentials like nonstandard and standard sized frames and shadow boxes are available on amazon or Ikea for home décor and look the same (hence the amazon basics brand).</li>
                    <li>Custom mat board workshops provide the mat board for any standard size frame or shadow box big or small as well as non-standard sizes.</li>
                    <li>Custom mat workshops allow the mat board to be cut without any compromise at a generously reduced priced when compared to allowing a custom framing shop to frame the piece.</li>
                    <li>If the picture mat is the elevating factor for my shadow box, then the custom frame full-service options are unnecessary when the frames are relatively cheap self sourced.</li>
                </ol>

                <br/><br/>

                <LazyLoad>
                    <img src={photo8} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Being a Canadian and a Toronto local I was surprised when I found a workshop that specialized in customized mat boards. This was excellent because not only would I be supporting local I also cut out an entire branch of unnecessary services.

                <br/><br/>


                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Custom-Mat.com opened new options for me. This workshop hosts an incredible website and limitless customization options exclusively focused on mat board. Their design tools were super advanced and user friendly, allowing me to customize a perfect fitting mat board to control the depth of my memorabilia items.

                <br/><br/>

                <LazyLoad>
                    <img src={photo11} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                In this case I was trying to bring glamour to 3 or 4 licence plates hosting names of the different places my father has lived in. With Father’s Day coming up later in the year I figured this project could kill two birds with one stone so to speak. These ugly rusty things would be transformed into statement pieces of memorabilia that display beautifully around his tool station of his garage. They even looked good just displayed anywhere in general. These little rusty things would also transform in a chic way that eliminated them as clutter in my mother’s mind. Something my father was proud of and longed to keep was elevated in a way that created a statement of good days lived, his own grand tour so to speak.

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Your girl didn't stop there… I have the most precious team Canada jersey. My God father gave it to me as a child. This valuable and precious keepsake has lived a forsaken life in the back of many closets. It was time to stop neglecting this thoughtful treasure. I purchased the largest shadow box, I could find on amazon in the most basic neutral white. Next, I ordered a custom cut matboard again from CustomMat. this time being a little less sure I decided to contact their design team. They quickly answered my questions regarding what was appropriate and best for preservation as well as display. I also learned about backboards.

                <br/><br/>


                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                By the time my phone call was done I realized my mat board order was already completed and I was ready to checkout. When everything arrived using a couple of craft pins, I secured my jersey. What a show piece. This would cost upwards of $500.00 if I didn't figure this all out myself. The jersey was free, the frame was $25.00, and the mat board was $39.95.

                <br/><br/>

                However, the workshop I used for my mat board (www.custom-mat.com) offers a bundle package at a whopping 50% off and often has promo codes. Free shipping across Canada & the USA.

                <br/><br/>

                You know I used that bundle deal…I got my mat boards for my Father’s Day gift I had planned as well as the mats I needed for the jersey together. The total project worked out to still be cheaper than a single framed jersey and I managed to create a gift in the process. This project was a total success. See photos below!

                <br/><br/>






                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                    <img src={photo9} className={"blog-content-photo"}/>
                    <img src={photo10} className={"blog-content-photo"}/>
                    <img src={photo11} className={"blog-content-photo"}/>
                    <img src={photo12} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>


            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>




        </div>
    }
}