import React from "react";

import {productDB} from "../comp-pricing/productDB";

export default class QuantityDiscount extends React.Component {

    constructor() {
        super();
        this.state = {
            penDrawing: false,
            french: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }


    render() {
        return <div className={"pricing-table-container fair-pricing-discount-section-container"}>
            <div className="product-listing-header">
                {this.state.french ? "ACHETEZ PLUS, ÉCONOMISEZ PLUS" : "Buy More, Save More"}
            </div>
            <div className="product-listing-desc">
                {this.state.french ? "Profitez d’une réduction pouvant aller jusqu’à 40 % à l’achat de plus d’un article." : "Enjoy up to 40% off when you buy more than one item."}
                <br/>
                {this.state.french ? "Les remises s’appliquent même si vous achetez des tailles, des couleurs ou des produits différents." : "Discounts apply even if you buy DIFFERENT sizes, colours, or products."}
                <br/>
                {this.state.french ? "La réduction s'applique à la quantité globale." : "Discount applies to overall quantity."}
                <br/>
            </div>

            <table className="pricing-table">
                <tbody>
                <tr>
                    <th className="faq-table-row">
                        {this.state.french ? "Nombre d’articles" : "Item Count"}
                    </th>
                    <th className="faq-table-row">
                        {this.state.french ? "Taux de Réduction" : "Discount Rates"}
                    </th>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">2 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier2 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">3 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier3 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">5 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier4 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">7 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier5 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">10 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier6 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">20 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier7 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">50 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier8 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">100 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier9 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">250 {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier10 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row">500+ {this.state.french ? "articles" : "items"}</td>
                    <td className="faq-table-row"><b>{productDB.discountTier.tier11 * 100}% {this.state.french ? "de réduction" : "off"}</b></td>
                </tr>
                </tbody>
            </table>

        </div>


    }
}