import React from "react";
import {Helmet} from "react-helmet";


export default class UserPolicies extends React.Component {
  render() {
    return <div className={`user-policies-container`}>
      <Helmet>
        <title>Order Custom Mat Board & Frames Online • Delivered to your door - Our Policy </title>
        <meta name="description" content="We custom cut your mat boards. Upgrade your retail picture frames. Make them look custom! Ship anywhere in Canada and US." />
      </Helmet>

      <div className={`user-policy-header`}>
        General Policies and<br/> User Agreement Terms
      </div>
      <div className={`user-policy-paragraph-narrow`}>
        Please read our general policies and user agreement below. If you have any questions, contact us via hello@custommat.ca
      </div>


      <div className={`user-policy-subheader`}>
        Details User Terms, Agreements and Privacy Clause
      </div>
      <div className={`user-policy-paragraph`}>
        Custom Matboards & Frames Canada (“Custom Mat”, “Custom Mat Canada”, “we”, or “us”) is a Canadian Limited Liability Corporation, incorporated federally and provincially in Canada under Custom Matboards & Frames (1201134 BC LTD and CustomMat E-Commerce Platform Limited).
      </div>
      <div className={`user-policy-paragraph`}>
        We have Terms & Conditions of Service apply to our website, services, and any of our digital assets. The use of the Site, including the purchase of any Products (as defined below) is subject to the following terms and conditions (the “Terms”) as well as the terms of our Privacy Policy listed separated below.
      </div>
      <div className={`user-policy-paragraph`}>
        By using our website and services, you are indicating to us that you agree with our terms and policies. If you do not agree with our terms, please do not use our site or services. If you have any questions about the Terms, please contact us at hello@CustomMat.ca.

      </div>
      <div className={`user-policy-paragraph`}>
        Our website allows you to learn about picture framing and interior design. We also market picture frames and related peripherals. We may change, suspend, or discontinue any aspect of the website or our services at any time, including availability of the site, services, products, or any feature, without a notice or liability.
      </div>


      <div className={`user-policy-subheader`}>
        Electronic Communications
      </div>
      <div className={`user-policy-paragraph`}>
        By visiting the site and/or communicating with us by email and/or telephone (including voice calls and/or text messages, collectively, “Electronic Communications”), you are communicating with us electronically and agreeing that we may contact you via electronic communications. You agree that all such electronic communications (including all notices, disclosures, warranties, and agreements) satisfy any legal requirement that such communication be in writing.
      </div>

      <div className={`user-policy-subheader`}>
        Content & Intellectual Property
      </div>
      <div className={`user-policy-paragraph`}>
        You may not copy or use any our contents and/or intellectual property (collectively, “Properties”) without a prior consent. All properties are owned by Custom Mats Canada and/or affiliated partners. Our properties include our copyrights, designs, and software.
      </div>

      <div className={`user-policy-subheader`}>
        Purchasing Products
      </div>
      <div className={`user-policy-paragraph`}>
        The prices that we display on our website do not include additional federal, provincial/state, and/or local taxes. We will inform you about applicable taxes before you make purchase. All amounts on our websites and services are listed in Canadian Dollars (or in US Dollars if you select US Dollar option).
      </div>
      <div className={`user-policy-paragraph`}>
        The payments will be linked with Stripe, our third-party payment processor. Your credit card information and handling will be done through Stripe. Please note that we do not save your credit card information on your database. Your order details and transaction information are encrypted and stored in our database.
      </div>

      <div className={`user-policy-subheader`}>
        Return Policy and Other Refunds
      </div>
      <div className={`user-policy-paragraph`}>
        By commissioning your project to us, you are agreeing that replacements or refunds of the work will not be available since our works are highly customized.
      </div>
      <div className={`user-policy-paragraph`}>
        In addition, by commissioning your project to us, you understand that maximum liability that we can be held liable is CAD 300. We cannot take any liability over CAD 300.
      </div>

      <div className={`user-policy-subheader`}>
        Truthful Communications
      </div>
      <div className={`user-policy-paragraph`}>
        Our customers promise that the information that they provide to Custom Mats Canada will be truthful, accurate, current, and complete. We cannot be liable for problems or issues that are incurred as a result of untruthful, inaccurate, non-current, and/or incomplete information provided by our clients.
      </div>

      <div className={`user-policy-subheader`}>
        Legal Jurisdiction
      </div>
      <div className={`user-policy-paragraph`}>
        These Terms and any document relating thereto shall be governed by and interpreted according to the laws of the Province of British Columbia, Province of Ontario, and applicable federal laws of Canada.
      </div>

      <div className={`user-policy-subheader`}>
        Limitation on Liability
      </div>
      <div className={`user-policy-paragraph`}>
        You understand and agree that Custom Mats Canada cannot be held liable for any direct, indirect, incidental, exemplary, or special damages, including, but not limited to damages for loss of profits (including goodwill), data, and any intangible losses, even if Custom Mats Canada has advised such an action. Please note that purpose of our information is for guidance only. By using our product, you agree that you are proceeding and using our products at your own risk.
      </div>
      <div className={`user-policy-paragraph`}>
        To the fullest extent possible by law, our maximum liability arising out of the site, our services, and our partners, regardless of the cause of an action (whether in contract, breach of warranty, tort, or any other possible legal matters), will NOT exceed priced that you paid for any products or CAD 300, whichever is greater.
      </div>

      <div className={`user-policy-subheader`}>
        Privacy and Security Policy
      </div>
      <div className={`user-policy-paragraph`}>
        We respect the federal Personal Information Protection and Electronic Documents Act ("PIPEDA") and our policies and practices are in place to comply with the PIPEDA and other applicable laws.
      </div>
      <div className={`user-policy-paragraph`}>
        Like most of online shopping platforms, Custom Mats Canada collect information that are both non-personal and personally identifiable information (PII). Our PIIs include, but not limited to, our clients' name, e-mail address, home address, and telephone number. Like other online businesses, we can use this information for marketing purposes. We can share this information with our marketing partners. For credit card purchases, your credit card information is encrypted and stored safely on our third-party platform database. Please note that we take your credit card information but does not store on our database for better security. Custom Mats Canada only collect information that are necessary and beneficial to our transactions.
      </div>
      <div className={`user-policy-paragraph`}>
        By using Custom Mats Canada, you allow us to contact you via email or telephone for framing order details, order status, any exceptions, deals & promotions, and/or framing tips & advice.
      </div>
      <div className={`user-policy-paragraph`}>
        You have rights and options to withdraw your privacy consent at any time. Please contact us via email, and we will withdraw your consent by eliminating your personal information from our database.
      </div>
      <div className={`user-policy-paragraph`}>
        By transferring all information via secured transfer protocol (https) and additional encryption on our database, we do our best to protect your personal information against such risk as loss, theft, unauthorized access, disclosure, or destruction.
      </div>
      <div className={`user-policy-paragraph`}>
        In highly unlikely scenario of personal information breach, Custom Mats Canada cannot be held liable if your personal information is stolen, intercepted, altered, and/or misused. There is no practical way of guaranteeing full security online. Therefore, we shall use reasonable efforts to ensure the security of the PIIs and other relevant user information.
      </div>
      <div className={`user-policy-paragraph`}>
        Please note that our partners and affiliated parties (including the link referral from our website) have different privacy and security policies. So, please consult with them if you have any questions on their privacy and security policies.
      </div>
      <div className={`user-policy-paragraph`}>
        Please note that our User Agreement and Privacy & Security Policies can be changed without a prior notice.
      </div>
      <div className={`user-policy-paragraph`}>
        By proceeding and using our product, we assume you have read, understood, and agreed with our terms and policies.
      </div>
      <div className={`user-policy-paragraph`}>
        <br/><br/>
        Please email us at hello@custommat.ca if you have any questions regarding shipping.
      </div>
    </div>
  }
}