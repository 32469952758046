import React from "react";
import {productDB} from "../../../javascript/components/comp-pricing/productDB";

export default class FetchPrices extends React.Component {

    constructor() {
        super();
        this.state = {
            penDrawing: false,
            french: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }


    render() {
        return <div className={"fair-pricing-discount-section-container"}>
            <div className="product-listing-header">
                {this.state.french ? "PRIX DU MANUFACTURIER DIRECT" : "FACTORY DIRECT PRICING"}
            </div>

            <div className="product-listing-desc">
                {this.state.french ? "Achetez directement sans sacrifier la qualité. Économisez jusqu’à 60 % sur le prix de détail." : "Buy direct without sacrificing quality. Save up to 60% off retail."}
                <br/>
                {this.state.french ? "Remises substantielles sur les achats en gros." : "Substantial discounts on bulk purchases."}
            </div>

            <table className="pricing-table">
                <tbody>
                <tr>
                    <th className="faq-table-row faq-table-row-size">
                        {this.state.french ? "Taille" : "Size"}
                        <br/>
                        <span className={"faq-table-row-subtext"}>
                            {this.state.french ? "(ou inférieure)" : "(or smaller)"}
                        </span>

                    </th>
                    <th className="faq-table-row">
                        <b>
                            {this.state.french ? "Notre Prix Régulier" : "Our Full Price"}
                            <br/>
                            <span className={"faq-table-row-subtext"}>
                                {this.state.french ? "(Achat 1 Feuille)" : "(Buying 1 Sheet)"}
                            </span>
                        </b>
                    </th>
                    <th className="faq-table-row mobile-no-display">
                        <b>
                            {this.state.french ? "Notre prix réduit" : "Our Discounted Price"}
                            <br/>
                            <span className={"faq-table-row-subtext"}>
                                {this.state.french ? "(Achat de 5 Feuilles)" : "(Buying 5 Sheets)"}
                            </span>
                        </b>
                    </th>
                    <th className="faq-table-row">
                        {this.state.french ? "Prix de détail estimé*" : "Estimated Retail Price*"}
                    </th>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">7 x 5 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.xSmall.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.xSmall.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "25$" : "$25"}</td>
                </tr>
                </tbody>




                <tbody>
                <tr>
                    <td className="faq-table-row-number">14 x 11 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.small1.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.small1.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "30$" : "$30"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">16 x 12 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.small2.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.small2.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "40$" : "$40"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">20 x 16 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.medium3.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.medium3.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "60$" : "$60"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">20 x 20 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.large1.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.large1.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "70$" : "$70"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">28 x 20 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.large2.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.large2.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "120$" : "$120"}</td>
                </tr>
                </tbody>

                <tbody>
                <tr>
                    <td className="faq-table-row-number">36 x 24 {this.state.french ? "pouces" : "inch"}</td>
                    <td className="faq-table-row"><b>${productDB.matboardOnlyPricing.xlarge2.price}</b></td>
                    <td className="faq-table-row"><b>${(productDB.matboardOnlyPricing.xlarge2.price * (1-productDB.discountTier.tier4)).toFixed(2)}</b></td>
                    <td className="faq-table-row">{this.state.french ? "140$" : "$140"}</td>
                </tr>
                </tbody>

            </table>

        </div>

    }
}